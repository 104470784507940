import App from './App.svelte';

var scInstances = [];
var windowses;
try {
	windowses = location.href.match(/i\=(.*)(&|$)/)[1]
} catch (error) {
	var windowses = ''
}
var windowshare;
try {
	windowshare = location.href.match(/v\=(.*)(&|$)/)[1]
} catch (error) {
	var windowshare = ''
}
var windowrequest;
try {
	windowrequest = location.href.match(/r\=(.*)(&|$)/)[1]
} catch (error) {
	var windowrequest = ''
}
Array.from(document.getElementsByClassName("the-shrtct")).forEach(element => {
	scInstances.push(new App({
		target: element,
		props: {
			edit: element.dataset.sesid || windowses || '',
			watch: element.dataset.watch || windowshare,
			req: element.dataset.req || window.screq || windowrequest

		}
	}))
})


export default scInstances;