import util from '../util'
export default ({
    StateManipulator: function () {
        this.set = function (machine, pins, doEval = true) { now = Array.isArray(pins) ? pins : [pins]; this.eval(machine, doEval); },
        this.add = function (machine, pins, doEval = true) { now = Array.isArray(pins) ? pins : [pins]; pins.forEach( (state)=> {if(now.indexOf(state)==-1) now.push(state);}); this.eval(machine, doEval); },
        this.del = function (machine, pin, doEval = true) { now.splice(now.indexOf(pin)); this.eval(machine, doEval) },
        this.isSet = function (machine, pins) { let result=false; if(!Array.isArray(pins)) pins=[pins]; pins.forEach( (pin) => { if(now.indexOf(pin) + 1) {result=true; return true} } ); return result},
        this.eval = function (machine, doEval = true) {
            if (doEval) { now.forEach((pin) => { if (this.states[pin] && this.states[pin].transition) this.states[pin].transition(machine, now); }) }
            this.notifyStateChange(machine, now)
        },
        this.notifyStateChange = function (machine) { this.stateChangeCallbacks.forEach( (cb) => {cb(machine, now); }) },
        this.onStateChange = function (cb) {
            this.stateChangeCallbacks.push(cb)
        }
        this.stateChangeCallbacks = [],
        this.states = []
    },
    State: function (options) {
        now = []
        //this.id = options.id ?? util.genId()
        if(options) Object.keys(options).forEach( (key)=>{this[key] = options[key]})
    }
})

let now = []