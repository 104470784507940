
import StatesManager from './inc/StatesManager'
import util from './util'
const sesMx = new StatesManager.StateManipulator()
var ses = new StatesManager.State({title: "Untitled", files: {}, segOrder: '', segs: {}, _k: util.genId(), store: 'gdrive'})
const loadSes = function(newSes) {
        let newSegs = {}
        Object.keys(newSes.segs).forEach( (sid) => newSegs[sid]=new StatesManager.State(newSes.segs[sid]) )
        ses=newSes
        ses.segs=newSegs
        sesMx.notifyStateChange(newSes, [])
        ses._k=newSes._k || util.genId()
        ses.store=newSes.store || 'gdrive' 
        //ses.files = ses.files && !Array.isArray(ses.files) ? ses.files : {}
    }
export {ses, sesMx, loadSes }

