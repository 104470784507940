<script>  
  import { onMount, afterUpdate } from "svelte";
  import StatesManager from "./inc/StatesManager.js";
  import util from "./util.js";
  import shorthash from "shorthash"

  import { segMx } from "./segstate";
  import { ses, sesMx, loadSes } from "./sesstate";

import * as jsonpatch from 'fast-json-patch';
import { applyOperation } from 'fast-json-patch';

  const server = isProd ? "https://shrtct.com" : "https://dev.shrtct.com"

  // Client ID and API key from the Developer Console
  const CLIENT_ID = "746727376075-svgf9crvtnu13out57f4or3at5t0nptb.apps.googleusercontent.com";
  const API_KEY = "AIzaSyAgfjbKw7lOmW2TsgqaITaAv0uo-OK07h4";
  const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
  const SCOPES = 'https://www.googleapis.com/auth/drive.file'; //https://www.googleapis.com/auth/drive.metadata.readonly
  let continueVid = false;
export let req;
export let watch;
export let edit;

  let iOS = util.isIOS();
  let contentType = ''
  let codec = ''
  let numSaves = -1;
  let autoPlay = false;
  let signInCb;
  let isPlaying = false;
  let respond = false;
  let create = false;
  let viewer = false;
  let initialized = false;
  let stripeLoaded = false;
  let willSave = false;
  let elements, stripe;
  let selectedPlan = 31;
  let context = '';
  let isAdmin = window.isAdmin || false
  let nDevices = 0;
  let fallbackRecord = false;
  let conflict = false;
  let userState = {
    gDriveAuthorized: false,
    loggedIn: window.shrtctUser ? true : false,
    email: window.shrtctUser || '',
    paid: false,
  }
  let videoDevices = []
  let permissions = {}
  let lastSaved='{}'
  let curSeg = {}
  let thenDo = false
  let sesNow = []
  let segNow = []
  let vidplayback = {}
  let streamsToCapture = {}
  let mediaRecorder = {}
  let isUploading = false
  let recordedChunks = []
  let trt = 0
  let recResume = -1
  let resumableUrl = ''
  let uploadedBytes = 0;
  let tmpTime = 0;
  let curSegId = '';
  let curSegFiles = []
  let orderedSegArr = []
  let segOrderArr = []
  let recordingScreen = false
  let allTracks = []
  let secondsRemaining = 0
  let countDownTimer
  const existingTitle = document.title

  // fix sessions
  $: if (!("files" in ses) || typeof ses.files != "object") {
    ses.files = {}
  }

  $:{
    ses.segOrder = segOrderArr.join(' ')
    orderedSegArr = []
    segOrderArr.forEach( (segid) => {
      orderedSegArr.push([segid, ses.segs[segid]])

    })
    orderedSegArr = orderedSegArr
  }

  $:  document.title = create ? ('#' + ses.title ) : ses.title ? (ses.title + ' - ' + existingTitle) : existingTitle

  $: { try{ 
      if (curSeg && "files" in curSeg) {
        curSegFiles = []
        let csf = curSeg.files.split(" ")
        csf.forEach( (file) => { 
            if("files" in ses && ses.files[file]) curSegFiles.push(ses.files[file]) 
          } )
      }
    } catch (e) {
      console.log(e)
    }
  }

  $: ses.title = ses.title ? ses.title.replace(/(\n|\<br\>)/ig, '') : ''

  async function saveSes(cb = ()=>{return true;}) {
    if (!("id" in ses) ) lastSaved='{}'
    let patch = jsonpatch.compare(JSON.parse(lastSaved), JSON.parse(JSON.stringify(ses)))
    if (patch) {
      const response = await fetch(server + `/wp-json/v1/patchSes?i=${ses.id || ''}&r=${req || ''}&n=${ses.nonce || ''}`, {
          method: 'PATCH', cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            //'X-WP-Nonce': window.wpnonce,
            'Content-Type': 'application/json-patch+json'
          },
          body: JSON.stringify(patch) // body data type must match "Content-Type" header
          });
        let data = await response.json(); // parses JSON response into native JavaScript objects
      
        
        if(!ses.id) {
          ses.id = data.id
          window.history.pushState('', '', '/create?i=' + ses.id);
        } 
        ses.nonce = data.nonce
        
        if(response.status==200) {
          let tmpLast = JSON.parse(lastSaved)
          tmpLast.nonce = data.nonce
          jsonpatch.applyPatch(tmpLast, patch)
          lastSaved = JSON.stringify( tmpLast )
        }
        if(response.status == 409 ) { // Conflict, so update our objects
          if (data.ses) { // a change occured and the whole session was modified somewhere else
            // apply a patch based on the server returned version to lastSaved and to our current changes
            try {
              data.ses = JSON.parse(data.ses)
              let serverPatch = jsonpatch.compare(JSON.parse(lastSaved), data.ses)
              lastSaved = JSON.parse(lastSaved)
              jsonpatch.applyPatch(lastSaved, serverPatch)
              lastSaved = JSON.stringify(lastSaved)
              jsonpatch.applyPatch(ses, serverPatch)
            } catch (e) {
              //big error, so just reset the whole goddamn session
              alert('Sorry, a conflict occured and your changes have been lost.')
              loadSession(data.ses)
            } finally {
              ses=ses
              ses.segs = ses.segs
            }
          } else {
            alert('something bad happened')
            location.reload()
          }
        }  
        ses = ses
        cb()
    console.log('saved');
    }
}

function loadSession(data) {
  loadSes(data)
  segOrderArr = data.segOrder.split(' ')
  lastSaved = JSON.stringify(data)
}
	
  

  async function updateSigninStatus(isSignedIn) {
        if (isSignedIn) {
          let googleUser = await gapi.auth2.getAuthInstance().currentUser.get()
                    userState.loggedIn = true; userState.email = googleUser.getBasicProfile().getEmail()
                    //if(googleUser.getBasicProfile().getEmail()!=window.shrtctUser) {
                      var id_token = await googleUser.getAuthResponse().id_token;
                      // log user in via shrtct
                     let data = await fetch(server + `/wp-json/v1/gapiSignIn?t=${id_token}`)
                     data = await data.json();
                     console.log(data);
                     //ses._wpnonce = data.nonce;
                    //}         
            userState.gDriveAuthorized = true
            initialized = true;

        } else {
            userState.gDriveAuthorized = false
            initialized = true ;

        }
    }

afterUpdate(() => {
	if (userState.loggedIn) {
    if(!willSave && needsSave()) {
      willSave=true;
      setTimeout(() => {saveSes(); willSave=false;}, 5000);  
    }
}})

function needsSave() {
  if (create || respond) {
    return JSON.stringify(ses) != lastSaved ? true : false;
  } else {
    return false;
  }
}

addEventListener("beforeunload", (evt) => {
    if (needsSave()) {
        saveSes();
        return 'Are you sure? Some changes have not been saved';
    }
});
  function initStates() {
    segMx.states = {
    inactive: { transition: toSegInactive },
      notReady: { transition: toSegNotReady },
      ready: { transition: toSegReady }, 
      paused: { transition: toSegPaused }, 
        startRecording: { },
        recording: { transition: toSegRecording},
        stopRecording: { transition: toSegStopRecording},
		awaitingUpload: { transition: toSegAwaitingUpload},

    };

    sesMx.states = {
      init: { transition: toSesInit },
      ready: { transition: toSesReady },
      signIn: { transition: toSignIn },
      payment: { transition: toStripePayment },
      	gdriveCannotUpload: { transition: ()=> {ses.lastErrMsg = "A network error occured, and we were not able to upload your video."}  },
		gDriveDenied: { transition: ()=> {ses.lastErrMsg = "You must give us permissions to store your videos on Google Drive. Shrtct is not able to view or access your personal files."}  },
		cameraPermsDenied: { transition: ()=> {ses.lastErrMsg = "You must give permission to use your recording devices."}  },
		deviceNotWorking: { transition: ()=> {ses.lastErrMsg = "Your audio and video recording devices cannot be accessed directly. You may upload a video file instead."}  },


    };

    sesMx.onStateChange((machine, now) => {
      ses=ses
      sesNow = now
    });
    segMx.onStateChange((machine, now) => {
      ses=ses;
      segNow = now
    });
  }

  async function toSignIn() {
    console.log("authorized=" + userState.gDriveAuthorized);
                userState.gDriveAuthorized = true
                try { 
                  await gapi.auth2.getAuthInstance().signIn() 
                  if(signInCb) {
                    signInCb(); 
                  }
                  } catch(err) {userState.gDriveAuthorized = false; console.log(err); sesMx.set(ses,["inactive","gDriveDenied"])}
  }

  function toSegInactive(seg) {
    thenDo = false;
    ses = ses
    curSeg = curSeg
	  closeTheater('sc-theater');
	  document.getElementById("sc-vidcontain").style.display="none"
	  ses = ses
    curSeg = curSeg
  }

  async function toSegReady(seg) {
    console.log(userState)
    //black out screen, check perms, make new session
    trt = 0
    recResume = -1
    isUploading = false;
    console.log('checking all permissions');
    if (!userState.loggedIn && !respond) { 
          thenDo = () => { segMx.set(seg, "ready"); };
          doSignIn();
      return;
    }
	  openTheater("sc-theater");
    uploadedBytes=0;
    recordedChunks=[];
    resumableUrl = '';
    if (!userState.gDriveAuthorized && !ses.store=='shrtct') {
		  sesMx.set(ses, ["ready", "gdriveDenied"])
			segMx.set(seg, "inactive")
      return
    }
    if (!resumableUrl && !await initResumableUpload(seg, curSegId)) { 
			sesMx.add(ses, ["ready", "gdriveCannotUpload"])
			segMx.set(seg, "inactive")
      return
    }
    if (permissions.camera == 'granted') {
      console.log('perms are clear')
    } else if (permissions.camera == 'prompt') {
      if (!confirm('We are about to ask you for permission to use your camera and microphone. Okay?')) {
        sesMx.set(ses, ["ready","cameraPermsDenied"])
        return false;
      };
    } else if (permissions.camera == 'denied') {
      alert('Hello, you will need to manually give us permission to access the camera. Look in the URL bar for permission settings for this site')
      sesMx.set(ses, ["ready","cameraPermsDenied"])
      return false;
    }
    if(!fallbackRecord) {
      await streamFromCamera(seg)
    }
    curSeg = curSeg
    segMx.set(seg, "paused")
    return true   
  }

  async function streamFromCamera(seg) {
    recordingScreen = false
    stopTracks()
    try {
        let devices = await navigator.mediaDevices.enumerateDevices()
        devices = devices.filter((d) => d.kind === 'videoinput');
        console.log(devices)
        nDevices = devices.length
      } catch (e) {
        console.log(e)
      } 
      recordedChunks = []
      try {
        var streams = await navigator.mediaDevices.getUserMedia({
                    audio: {echoCancellation: true, noiseSuppression: true}, video: { frameRate: { ideal: 30, max: 30 }, width: { ideal: 1280 }, height: { ideal: 720 } } //, deviceId: window.deviceId
                  })
      } catch (e)  {
        console.log(e)
        try {
          streams = await navigator.mediaDevices.getUserMedia({
                      audio:  {echoCancellation: true, noiseSuppression: true}, video: true } )          
        } catch (e2) {
          console.log(e2)
          fallbackRecord = true
          segMx.set(seg, "paused")
          sesMx.set(ses, ["ready","deviceNotWorking"])
          return false;
        }
      }

      await attachStreams([streams])
      
  }

  async function attachStreams(streams = []) { 
    allTracks = []
    try{
      let videoStream, audioStreams = [];
      streams.forEach(stream => {
        stream.getVideoTracks().forEach( (vidTrack)=> {
          if(!videoStream) { 
            vidTrack.onended = function () {
              segMx.set(curSeg, "stopRecording")
            }
            videoStream = vidTrack; 
            allTracks.push(vidTrack) }
          else { vidTrack.stop() }
        })
        stream.getAudioTracks().forEach( (audTrack)=> {
          audioStreams.push (audTrack)
          allTracks.push(audTrack)
        })           
      });
      let audioStream = mergeAudioStreams(audioStreams)
      streams = new MediaStream([audioStream,videoStream])
        vidplayback.srcObject = streams
        if(!recordingScreen) await vidplayback.play()
        streamsToCapture = streams; 
        mediaRecorder = await new MediaRecorder(streams, {  mimeType: codec  }); //audioBitsPerSecond: 128000, videoBitsPerSecond: 1800000
        mediaRecorder.start(1000)
        mediaRecorder.pause()
        mediaRecorder.requestData() // just kill first set of data
        mediaRecorder.ondataavailable = handleDataAvailable;
        function handleDataAvailable(event) {
          console.log("data-available");
          console.log(event.data.size)
          if (event.data.size > 0) {
            recordedChunks.push(event.data);
            upload(curSeg);
          } 
        }
      } catch(e) {
        console.log(e)
        fallbackRecord = true
      } finally {
      }
  }
  
  
  

      function upload(seg) {  
        if(isUploading) return;
        if(!resumableUrl) { console.log("no resumableUrl"); return;  }
        // multiples of 262144
        const optimalSize = 262144//262144;
        const maximalSize = optimalSize * 25
        var combinedChunk = new Blob()
        var readyBlob = new Blob()
		    uploadedBytes = uploadedBytes || 0
		    if(segMx.isSet(seg,"awaitingUpload") && seg.finishUploadCb && uploadedBytes == 0 && recordedChunks.length==0) seg.finishUploadCb() 
        if (recordedChunks.length > 0) {
            let bytesReady = 0, chunksToUse=0; recordedChunks.find( (chunk) => { chunksToUse++; bytesReady+= chunk.size; return bytesReady>=maximalSize ? true : false });
            combinedChunk = new Blob(recordedChunks.splice(0,chunksToUse), { type: contentType })
            if(combinedChunk.size < optimalSize && !segMx.isSet(seg,"awaitingUpload")) {
              // return it to stack
              recordedChunks.unshift(combinedChunk);
              return;
            }
            let isFinal = segMx.isSet(seg,"awaitingUpload") ? true : false;
            if(bytesReady>=optimalSize || isFinal ) {
                if(isFinal) {
					        readyBlob = combinedChunk                   
                } else {
                  let toByte = Math.floor(combinedChunk.size / optimalSize) * optimalSize
                  toByte = toByte > maximalSize ? maximalSize : toByte
                    readyBlob = combinedChunk.slice(0,toByte, contentType)
                    recordedChunks.unshift(combinedChunk.slice(toByte))
                }
            } else {
                return //try again with more data
            }
            let range = isFinal ? uploadedBytes + readyBlob.size : "*"
            isUploading = true;
            // let resumableOpts = ''
            // if(isFinal && contentType == 'video/quicktime') resumableOpts = '&container=tomp4'
            const uploadResumable = new XMLHttpRequest();
            uploadResumable.open('PUT', resumableUrl, true);
            uploadResumable.setRequestHeader('Content-Type', contentType);
            uploadResumable.setRequestHeader('X-Upload-Content-Type', contentType);

            uploadResumable.setRequestHeader('Content-Range', `bytes ${uploadedBytes}-${uploadedBytes+readyBlob.size-1}/${range}`);
            uploadResumable.onreadystatechange = function() {
                let isOkay = false;
                if(uploadResumable.readyState === XMLHttpRequest.DONE) {
                  if(uploadResumable.status == 200 ) {
                    isOkay = true;
                      if(isFinal) {
                      let results=JSON.parse(uploadResumable.response)
                      let metaFileId = util.genId()
                      if (ses.store=="gdrive") {
                        ses.files[metaFileId] = {bytes:uploadedBytes, store: ses.store, runtime: Math.round(trt), 'content-type': contentType, url: `https://drive.google.com/uc?id=${results.id}`, ...results}
                      } else {
                        ses.files[metaFileId] = {bytes:uploadedBytes, store: ses.store, runtime: Math.round(trt), ...results}
                      }
                      seg.files=metaFileId
                      ses = ses 
                      curSegId = curSegId
                      curSeg = curSeg
                      try {
                        document.getElementById("vidplayer").load()
                      } catch(e) {
                        console.log(e)
                      }
                      if(seg.finishUploadCb) seg.finishUploadCb(results)
                    }
                  }

                  if(uploadResumable.readyState === XMLHttpRequest.DONE && ( uploadResumable.status == 206 || uploadResumable.status == 308 )) {
                    isOkay = true;
                    console.log(uploadResumable.response);
                    uploadedBytes+=readyBlob.size;
                  }
                  if(uploadResumable.readyState === XMLHttpRequest.DONE && !isOkay) {
                    // throw it back on the upload heap to retry.
                    recordedChunks.unshift(readyBlob);              
                  } 
                  isUploading = false;
                  if (recordedChunks.length>0) upload(seg);
                }
            };
            uploadResumable.onerror = function() {
              alert('made it!')
              recordedChunks.unshift(readyBlob);
              ses.isUploading = false;
              //if(recordedChunks.length>0) upload(seg);
            }
            try {
              uploadResumable.send(readyBlob);
            } catch (err) {
              console.log(err)
              ses.isUplading = false;
            }       
        }
    }


  function toSegPaused(seg) {
    secondsRemaining = 0
    recResume++
    if ( recResume) { 
      trt+=(Date.now()/1000 - tmpTime)
    }
  document.getElementById("sc-vidcontain").style.display="block"
  try { 
    mediaRecorder.requestData();
    mediaRecorder.pause(); 
    if(recordingScreen) clearInterval(countDownTimer)
  }
  catch (e) { console.log(e)} finally {
    curSeg = curSeg
  }

	
  }

  function toSegRecording(seg) {
    tmpTime = Date.now() / 1000
    if(recordingScreen) {
      secondsRemaining = 3
      countDownTimer = setInterval(function() {
        secondsRemaining--
        if(secondsRemaining==0) {
          mediaRecorder.resume();
        }
      }, 1000)    
    } else {
      mediaRecorder.resume();
    }
    curSeg = curSeg
  }

  function toSegStopRecording(seg) {
    secondsRemaining = 0
	seg.finishUploadCb = async (results)=>{
                    if(ses.store=="gdrive") {
                      let resp = await gapi.client.drive.permissions.create({
                        'fileId': results.id,
                        'resource': {
                            "withLink": true,
                            "role": "reader",
                            "type": "anyone"
                        }
                    })
                  }
          console.log(results)
          delete seg.finishUploadCb
          segMx.set(seg,"inactive")
          saveSes()
          //needed? setActiveSeg(seg, true)
        }
        try {
          stopTracks()
          if (vidplayback) vidplayback.pause()
          if (streamsToCapture) {
              streamsToCapture.getTracks().forEach(function (track) {
                  track.stop();
              });
          }
          if(recordingScreen) clearInterval(countDownTimer)
        } catch(e) {
          console.log(e)
        }
        curSeg = curSeg
        segMx.set(seg, "awaitingUpload")
  }

  function toSegAwaitingUpload(seg) {
    if(recordedChunks.length > 0 ) {
      upload(seg)
    } else {
      segMx.set(seg, "inactive")
      console.log('did not get it all')
    }
    curSeg = curSeg

	    
  }

  function toSegNotReady(seg) {
    closeTheater('sc-theater');
    curSeg = curSeg
  }
  


  function toSesInit(ses) {
    //get gapi
    let script = document.createElement("script");
    script.onload = function() {
		  gapi.load('client:auth2', () => {
            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES
            }).then(function () {
                userState.gDriveAuthorized = false
                gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
                updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
				sesMx.set(ses, "ready");
            }, function (error) {
                console.log(error);
            });
		  })
	}
    
    script.onreadystatechange = () => {
      if (this.readyState === "complete") this.onload();
    };
    script.src = "https://apis.google.com/js/api.js";
	document.head.appendChild(script);
	if(!ses.segs.length>0) addSeg()
  }

  function toSesReady(ses) {
    closeTheater('sc-auth')
	if (curSeg) segMx.set(curSeg,"inactive")
	vidplayback=document.getElementById('sc-vidplayback');
  }

  function addSeg() {
    let metaSegId = util.genId()
    ses.segs[metaSegId]= new StatesManager.State({ title: "blah"});
    segOrderArr.push(metaSegId)
    setActiveSeg(metaSegId)
  }

  function setActiveSeg(segid, manual) {
    if (segOrderArr.indexOf(segid)==-1) return
    curSegId = segid
    curSeg = ses.segs[segid]
    //fixOrder()
    // curSeg = ses.segs[segid];
    if(manual && autoPlay && isPlaying) { continueVid = true } else { continueVid = false }
  }

  function fixOrder() {
      let pos = segOrderArr.indexOf(curSegId)
      pos = pos > -1 ? pos : 0
      orderedSegArr = Object.entries(ses.segs).sort( (el1, el2) => ((segOrderArr.indexOf(el1[0])) >-1 ? segOrderArr.indexOf(el1[0]) : segOrderArr.length+1) - segOrderArr.indexOf(el2[0]) )
      segOrderArr = segOrderArr.length != orderedSegArr.length ? orderedSegArr.map(el=>el[0]) : ses.segOrder.split(" ")
      ses.segOrder = segOrderArr.join(" ")
      if (!curSegId && orderedSegArr[0] && orderedSegArr[0][0]) setToFirstSeg()
      if (!segOrderArr[pos]) {
        pos = (pos - 1) > -1 ? pos-1 : 0        
      }
      if (!(segOrderArr.indexOf(curSegId) > -1) ) {
        setActiveSeg(segOrderArr[pos])
      }

  }

  function clickRecord(seg) {
    segMx.set(seg, "ready");
  }

  function closeTheater(elid) {
    document.getElementById(elid).style.display = "none";
    document.body.style.overflowY = "auto";
    document.body.style.height='100%';
    ses=ses
  }

  function openTheater(elid) {
    document.getElementById(elid).style.display = "block";
    document.body.style.overflowY = "hidden";
    document.body.style.height='100vh';
  }
  function closeSeg(seg) {
	  segMx.set(seg,"inactive")
  }
  async function initResumableUpload(seg, segid) {
    if (ses.store=="gdrive") {
        const user = gapi.auth2.getAuthInstance().currentUser.get();
        const oauthToken = user.getAuthResponse().access_token;
        const initResumable = new XMLHttpRequest();
        initResumable.open('POST', 'https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable', false);
        initResumable.setRequestHeader('Authorization', 'Bearer ' + oauthToken);
        initResumable.setRequestHeader('Content-Type', 'application/json');
        initResumable.setRequestHeader('X-Upload-Content-Type', contentType);
        initResumable.onreadystatechange = function() {
        };
        initResumable.send(JSON.stringify({name: 'shrtct-vid-'+segid, mimeType: contentType, "Content-Type": contentType})) ;
        resumableUrl = initResumable.getResponseHeader('Location') || false
        if (resumableUrl) {
            return true;
        } else {
            return false;
        }
    } else if (ses.store=="shrtct") {
        let data = await fetch(server + `/wp-json/v1/getResumableUrl?s=${ses.id}&r=${req}`, 
        { headers: { 'Content-Type': contentType
    }}
) 
        data = await data.json() 
        console.log(data);
        if(data['url']) {
          resumableUrl = data['url'];
          return true;
        } else {
          return false;
        }      	
    }
  }

  onMount(async () => {
    if (isAdmin) userState.paid = true
    if(window.shrtctUser) userState.loggedIn = true; userState.email = window.shrtctUser
    if(watch) {
      autoPlay = true;
      viewer=true;
      console.log("watching session");
      getWatchSes(watch)
    }
  else if(edit) {create=true; console.log("found sessionid " + edit); getUserSession(edit);
}
  else if (req) {
    respond=true
    getReqSession(req);
  } else {
    create = true;
  }
  if (create || respond) {

    try {
      if(MediaRecorder.isTypeSupported('video/mp4;codecs=h264')) {
        //alert ('Set to webm!')
        contentType = "video/mp4"
        codec = "video/mp4;codecs=h264"
      } else if(MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
        contentType = "video/webm"
        codec = 'video/webm;codecs=h264'
      } else {
        codec = ''
        contentType = "video/mp4"
      }
    } catch(e) {
      console.log(e)
      //alert('Set to mp4')
      codec='video/mp4;codecs=h264'
      contentType = 'video/mp4'
      fallbackRecord = true
    }
  }  

  if (create) {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn.jsdelivr.net/npm/sortablejs@latest/Sortable.min.js';
    script.onload=()=>{
      let sortable = new Sortable( document.getElementById('sc-session'), { 
        animation: 150,
        handle: '.sc-handle',
        onEnd: function (evt) {
          sortable.sort(segOrderArr)
          let moved = segOrderArr[evt.oldIndex]
          segOrderArr.splice(evt.oldIndex, 1)
          segOrderArr.splice(evt.newIndex, 0, moved)
          segOrderArr = segOrderArr
          saveSes()
        }

      })
    }
    document.body.appendChild(script);     
  }
  //getLastSession()
  initStates();
  sesMx.set(ses, "init");
  lastSaved = JSON.stringify(ses)

  // get permissions
  try {
    navigator.permissions.query({ name: 'camera' }).then(function (result) {
      permissions.camera=result.state 
     })
  
  } catch (e) {
    console.log(e)
    permissions.camera = 'unknown'
  } finally {

  }
  });
  

 
  async function getLastSession() {
	let data = await fetch(server + '/wp-json/v1/getLastSes')
	data=await data.json()
	data = JSON.parse(data[0]['post_content'])
  lastSaved = JSON.stringify(data)
  setToFirstSeg()
  sesMx.set(ses,"ready")
  }

  async function getUserSession(sid) {
	let data = await fetch(server + '/wp-json/v1/getUserSes?sid=' + sid)
	data=await data.json()
  data = JSON.parse(data[0]['post_content'])
  lastSaved = JSON.stringify(data)
  loadSession(data);
  setToFirstSeg()
  sesMx.set(ses,"ready")
  }

  async function getWatchSes(w) {
	let data = await fetch(server + '/wp-json/v1/getWatchSes?w=' + w)
	data=await data.json()
  data = JSON.parse(data[0]['post_content'])
  lastSaved = JSON.stringify(data)
  loadSession(data);
  setToFirstSeg()
  sesMx.set(ses,"ready")
  }

  async function getReqSession(r) {
	let data = await fetch(server + '/wp-json/v1/getReqSes?r=' + r)
	data=await data.json()
  data = JSON.parse(data[0]['post_content'])
  lastSaved = JSON.stringify(data)
  loadSession(data);
  setToFirstSeg()
  sesMx.set(ses,"ready")
  }

  function setToFirstSeg() {
    setActiveSeg(segOrderArr[0])
  }

  function setToNextSeg() {
    setActiveSeg(segOrderArr[segOrderArr.indexOf(curSegId)+1])
  }

  function hasNextSeg() {
    return segOrderArr[segOrderArr.indexOf(curSegId)+1] ? true : false
  }


  function onVideoEnd() {
    isPlaying = false
    if( !create ) {
      if(autoPlay) {
        if(hasNextSeg()) {
          setToNextSeg()
          continueVid = curSeg.files ? true : false
        }
      }
    }
  }

  function onVideoStart(mp) {
    if (continueVid) mp.srcElement.play()
    continueVid = false;
  }

  function shareSes() {
    ses.sharetoken=shorthash.unique(ses._k + ses.id + "share-token") 
    saveSes()
  }

  function requestSes() {
    if(!userState.paid) {
        if( confirm ("Video requests cannot be stored on your Google Drive account. For this, you will need to choose a Shrtct package. Ready?") ) {
          sesMx.set(ses, 'payment');
        }

    } else {
      ses.reqtoken=shorthash.unique(ses._k + ses.id + "request-token")
      ses.store = 'shrtct'
      saveSes()
    }

    
  }

  function delRequestSes() {
    delete ses.reqtoken;
    ses.store = 'gdrive'
    ses = ses
    saveSes()
  }

  function delShareToken() {
    delete ses.sharetoken
    ses = ses
    saveSes()
  }

  function doSignIn() {
      openTheater('sc-auth')
  }

function setStorage(loc) {
  if (!ses.scAuth && loc == "shrtct" ) {
      ses.transitionCb = function() { setStorage(loc); }
      alert("You will need a Shrtct account")
      sesMx.set(ses,"payment")
  } else { 
      ses.store = loc
      delRequestSes()
  }
}

 function deleteSegment(segid) {
   if ( confirm("really?") ) {
    delete ses.segs[segid]
    let idx = segOrderArr.indexOf(segid)
    segOrderArr.splice(idx,1)
    segOrderArr=segOrderArr
    setActiveSeg(segOrderArr[idx > 0 ? idx-1 : 0])
    saveSes()
   }
   
  }

  function toStripePayment(ses) {
    if (!stripeLoaded) {
      let script = document.createElement("script");
      script.onload = function () { stripeLoaded = true; toStripePayment() }
      script.src = "https://js.stripe.com/v3/";
      document.head.appendChild(script);
    } else {
      openTheater('sc-stripe');
      stripe = Stripe(window.stripe);

    }
  }

  async function goToPay(pid) {
    document.getElementById("choose-the-plan").classList.add('sc-dn');
    const response = await fetch(server + `/wp-json/v1/pay?plan=${pid}`, {
          method: 'GET', cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          headers: {'Content-Type': 'application/json'  },
        });
        let data = await response.json(); 
// Create an instance of Elements.
      elements = stripe.elements();

      // Create an instance of the card Element.
      var card = elements.create('card',);

      // Add an instance of the card Element into the `card-element` <div>.
      card.mount('#card-element');

      card.on('change', ({error}) => {
        let displayError = document.getElementById('card-errors');
        if (error) {
          displayError.textContent = error.message;
        } else {
          displayError.textContent = '';
        }
      });
      stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: card,
          billing_details: {
          }
        },
        setup_future_usage: 'off_session'
      }).then(async function(result) {
        if (result.error) {
          // Show error to your customer
          console.log(result.error.message);
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            console.log(result);
            const response = await fetch(server + `/wp-json/v1/confirmPayment?${result.paymentIntent.id}`, {
            method: 'GET', cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {'Content-Type': 'application/json'  },
          });
          let data = await response.json(); 
          if(data.result='success') {
            userState.paid = true;
          } else {
            alert('Your account could not be set up. Please contact customer service.');
          }
            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback execution
            // Set up a webhook or plugin to listen for the payment_intent.succeeded event
            // to save the card to a Customer

            // The PaymentMethod ID can be found on result.paymentIntent.payment_method
          } else {
            alert('Payment failed');
            console.log(result);
          }
        }
      });


      // Submit the form with the token ID.
      function stripeTokenHandler(token) {
        // Insert the token ID into the form so it gets submitted to the server
        var form = document.getElementById('payment-form');
        var hiddenInput = document.createElement('input');
        hiddenInput.setAttribute('type', 'hidden');
        hiddenInput.setAttribute('name', 'stripeToken');
        hiddenInput.setAttribute('value', token.id);
        form.appendChild(hiddenInput);

        // Submit the form
        form.submit();
      }
    }

  function toggleShare() {
    ses.sharetoken ? delShareToken() : shareSes()
  }
  function toggleRequest() {
    ses.reqtoken ? delRequestSes() : requestSes()
  }
  function printSegmentTime(seg) {
    try {
      if(seg.files) {
      let runningTime = 0;
      seg.files.split(" ").forEach( (file) => {
        if(ses.files[file] && ses.files[file].runtime ) {
          runningTime += ses.files[file].runtime
        }
      })
      return runningTime > 3600 ? new Date(runningTime * 1000).toISOString().substr(11, 8) : new Date(runningTime * 1000).toISOString().substr(14, 5)
    } else {
      return '00:00';
    }
    } catch(e) {
      return '00:00';
    }
    
  }

  function doCancel(seg) {
    resumableUrl = ''
    recordedChunks = []
    segMx.set(seg, "stopRecording")
  }

  function doFallbackRecord(seg) {
    let fbr = document.getElementById('fallback-record')
    fbr.click()
    fbr.onchange = function () {
      if(fbr.files) {
        console.log(fbr.files);
        //alert (JSON.stringify(fbr.files))
        recordedChunks = [ fbr.files[0].slice(0, fbr.files[0].size, fbr.files[0].type) ];
        contentType = fbr.files[0].type.toLowerCase()
        segMx.set(seg, 'stopRecording')
      } else {
        segMx.set(seg, 'inactive')
      }
    }
  }

  async function deleteSession() {
    if (confirm('Deleting is permanent. Are you sure?')) {
      let data = await fetch(server + `/wp-json/v1/ses?i=${ses.id}`, {method: 'DELETE'} )
      data = await data.json();
      console.log(data);
      window.location.replace('/');

    }
  }

  function toggleElById(elId) {
    document.getElementById(elId).classList.toggle("sc-dn");
  }

  function copyThisEl(event) {
    navigator.clipboard.writeText(this.innerText);
  }

  function copySession() {
    let sesCopy = JSON.parse(JSON.stringify(ses)) // deep copy
    delete sesCopy.id
    delete sesCopy.files
    sesCopy.title = 'Copy of ' + sesCopy.title
    for(const [key, val] of Object.entries(sesCopy.segs)){
      if(val.files) delete val.files
    }
    loadSession(sesCopy)
    delRequestSes()
    delShareToken()
    saveSes(() => {window.location.replace(location.href) })
    
  }

  async function toggleScreenRecord() {
    stopTracks()
    if (!recordingScreen) {
      streamFromScreen(curSeg)
    } else {
      streamFromCamera(curSeg)
    }

  }

  function validateSesTitle() {
    ses.title = ses.title || 'Untitled' 
    return true
  }


  async function streamFromScreen(seg) {
    recordingScreen = true
    stopTracks()
    recordedChunks = []
    try {
      var voiceStreams = await navigator.mediaDevices.getUserMedia({ audio:  {echoCancellation: true, noiseSuppression: true}, video: false })
      var desktopStreams = await navigator.mediaDevices.getDisplayMedia({ video: {
                                                                          cursor: "always"
                                                                        },
                                                                        audio: {
                                                                          echoCancellation: true,
                                                                          noiseSuppression: true,
                                                                          sampleRate: 44100
                                                                        }
                                                                      });
    } catch (e)  {
        console.log(e)
        streamFromCamera()
        return
      }
      await attachStreams([voiceStreams, desktopStreams])
  }


  async function stopTracks() {
        try {
          if (vidplayback) vidplayback.pause()
          mediaRecorder.stop();
        } catch(e) {
          console.log(e)
        }
        if (allTracks) {
              allTracks.forEach(function (track) {
                  track.onended = null
                  track.stop();
              });
          }
  }

function mergeAudioStreams (streams=[]) {
  const context = new AudioContext();
  const destination = context.createMediaStreamDestination();
  var combinedAudioTrack;
  streams.forEach( (track) => {
    const singleMediaStream = new MediaStream()
    singleMediaStream.addTrack(track)
    allTracks.push(track)
    const source = context.createMediaStreamSource(singleMediaStream)
    source.connect(destination);
    combinedAudioTrack = destination.stream.getAudioTracks()[0]
  })    
  return combinedAudioTrack;
};

  
</script>
<div class="on-top-of" style="width:100%;">
<div style="width:100%;">
<div id="sc-top" class="mw550 sc-contain" style="width:100%;">
{#if (respond || create) && fallbackRecord}
  <p style="color:rgba(255,0,0,0.8);background-color:rgba(0,0,0,0.8);padding:.33em;line-height:1.5em;border-radius:3px;">
    Your browser does not support recording from the webcam. :-( <br><br>You can still upload a video using the camera on your phone.
    For best results, use any desktop or Android version of Chrome.
  </p>
{/if}
<div style="text-align:right">{#if !userState.loggedIn && (create) }  <a on:click={doSignIn} style="cursor:pointer;">Sign in</a>{:else if userState.loggedIn } Logged in: {userState.email}{:else}&nbsp;{/if} </div>
<div class="bg-dark-red {!sesMx.isSet(ses,["gDriveDenied","cameraPermsDenied","deviceNotWorking"]) ? "sc-dn" : "" }" style="padding:.33em;border-radius:3px">
	{ses.lastErrMsg}
</div>

        {#if !create}
        <h1 class="sc-title">{ses.title}</h1>
        {/if}


</div>
</div>
    <div class="nearer">
      <div id="sc-mid" class="mw550 sc-contain">
      {#if create}
      <span maxlength="70" style="margin-bottom: 4rem;" class="textbox title-editable sc-title sc-editable " on:keydown={e=> ( e.key==='Enter' && e.target.blur() && e.preventDefault() ) || (ses.title.length > 75 && e.key!='Delete' && e.key!='Backspace' && e.preventDefault() )  }   on:change={(e)=>{ if(ses.title.length > 75) e.preventDefault() } }
      on:focus={(e) => {if(ses.title=='Untitled') e.target.select() }}
      on:blur={(e) => validateSesTitle() }
        bind:textContent={ses.title} contenteditable></span>
      {/if}
<div class="sc-session" id="sc-session">
	    {#if viewer}  
      <div id="sc-ap-head" on:click={ () => {autoPlay = !autoPlay}}><i class="{autoPlay ? 'icon-toggle-on' : 'icon-toggle-off'}"></i>
      Auto-play</div>
      {/if}
  {#each orderedSegArr as [segid, seg], i}
	  <div class="sc-seg" data-id={segid} on:click={() => setActiveSeg(segid,true)}>
      <div style="display:flex; flex-direction:row;" class="sc-handle">
        <div class="sc-seg-title {curSeg == seg && create ? 'sc-dn' : ''}">
          <span class="{curSeg == seg ? 'sc-dn' : ''}"><i class="icon-down-open"></i> </span> {i + 1}. {seg.title}
        </div>
        <div style="margin-left:auto;white-space: nowrap;margin-bottom:1rem;"><i class="icon-clock"></i> {printSegmentTime(seg)}</div>

      </div>

      
      
	  {#if create}
      <div class="sc-selected {curSeg != seg ? 'sc-dn' : ''}" style="margin-bottom: 2rem;">
      
        <div class="sc-title-combo"> 
        <div class="sc-title-combo-2">
        <input maxlength="100" placeholder="...enter a segment title"
          type="text"
          bind:value={seg.title}
          class="sc-input-seg-title sc-input" />
          </div>
</div>
        
      </div>
      {/if}
 

            <div class="sc-seg-wrapper-active {(create || respond) && curSeg==seg ? '' : 'sc-dn'}" style="display:flex;flex-wrap:wrap;">
             {#if curSeg == seg && curSeg.files}{#each curSegFiles as file, count}<div style="display:flex;margin-right:auto;margin-left:auto;flex-direction:column;margin-bottom:1rem;flex-basis:100%;" >
      {#if iOS && file.store == 'gdrive'}
        <iframe src="https://drive.google.com/file/d/{file.id}/preview" style="width:100%; max-height:66vh;"></iframe>
      {:else}
      <div style="display:flex; flex-direction:column; margin-right:auto; margin-left:auto;">
        {#if create}
          <div style="font-size: .875rem; text-align: right; margin-bottom: .5rem;" on:click={() => location.href=file.url+ '&dln=1'}><i class="icon-download-cloud"></i> Download</div>
        {/if}

        <video style="border-radius: 5px;" id="vidplayer" data-vid={segid} playsinline on:playing={()=>{isPlaying=true}} on:pause={()=>{isPlaying=false}} on:abort={()=>{isPlaying=false}} on:ended="{onVideoEnd}" on:canplay="{onVideoStart}" class="sc-video-preview" controls>
            <source src={file.url ? file.url : (server + `/wp-json/v1/streamFile?s=${file.s}`)} type={file.type || file['content-type']}>
          </video> 
        </div>
        {/if}
        </div>
        

      {/each}   
      {/if}    
        {#if create || respond}<div style=" margin-bottom: 1rem; margin-right: 1rem;" class="{curSeg!=seg ? 'sc-dn' : ''}">
		<a on:click={clickRecord(seg)} class="sc-btn sc-btn-primary  "><i style="color:white;" class="icon-record"></i>&nbsp;
          {curSeg && 'files' in curSeg ? 'Re-Record' : 'Record'}
        </a></div>

        {/if}
        
      
    <div style="flex-grow:1;text-align:right;">
    {#if curSeg == seg && curSeg.files}
    
      
        {:else}
          <div class="{curSeg == seg ? '' : 'sc-dn' }">No video yet recorded!</div>
          
        {/if}
        {#if create}
        <div>
        <a on:click={deleteSegment(segid)} style="font-size: .875rem;" class="{curSeg!=seg ? 'sc-dn' : ''} ">
          <i class="icon-trash-empty"></i> Delete Segment
        </a></div>
        {/if}
        </div>
    </div>
              </div>

  {/each}

  
    </div>

    {#if create}
  	<div style="cursor:pointer;    margin-top: 4rem;">
      <a  class="sc-btn sc-btn-secondary {userState.loggedIn ? 'sc-dn' : ''}" on:click={doSignIn}><i class="icon-floppy"></i>&nbsp;Save</a>
      <a  class="sc-btn sc-btn-secondary" on:click={addSeg}>+ Add Segment</a>
    </div> 
    
    {/if}
</div>
</div>

    {#if create}
    <div class="sc-footer" style="display:flex;flex-direction:column;align-items:center;">
    <div class="mw550">
    <table style="font-size: 1.5rem;color: #fff;"> 
      <tr on:click={() => toggleElById('ses-options')}>
        <td style="vertical-align: top;   text-align: right; padding-bottom: 2rem;"> <i class="switch icon-down-open"></i> </td>
        <td style="vertical-align: top;">Options 
              <div id="ses-options" class="sc-dn" style="margin: 1rem;text-align: left;font-size: 1.5rem;cursor:pointer; margin-bottom: 4rem;">
            <p on:click={deleteSession}> Delete session <i class="icon-trash-empty"></i></p>
            <p on:click={copySession}> Copy session <i class="icon-docs"></i></p>
          </div>
        </td>
      </tr>
      <tr style="margin-bottom: 4rem;">
        <td style="padding-bottom: 2rem; text-align: right; vertical-align: top;"> <i on:click={toggleShare} class="switch {ses.sharetoken ? 'icon-toggle-on ' : 'icon-toggle-off'}"></i> </td> 
        <td style="vertical-align: top;"> <span on:click={toggleShare} class="{ses.sharetoken ? 'sc-dn' : ''}">Share&nbsp;<i style="font-size: 1rem;" class="icon-export"></i></span> 
              <a target="_blank" class="{ses.sharetoken ? '' : 'sc-dn'}" href="/watch?v={ses.sharetoken}">This is the public URL</a>
              <div id="sc-share-url-to-copy" on:click={copyThisEl} style="font-size: 1rem; margin-left: 1rem;"  class="sc-copy {ses.sharetoken ? '' : 'sc-dn'}">{server + "/watch?v=" + ses.sharetoken}<i style="margin-left:1rem;" class="icon-docs"></i></div>
        </td>     
      </tr>
      <tr>
        <td style="padding-bottom: 2rem; text-align: right; vertical-align: top;"> <i on:click={toggleRequest} class="switch {ses.reqtoken ? 'icon-toggle-on ' : 'icon-toggle-off'}"></i> </td>
        <td style="vertical-align: top;"> <span on:click={toggleRequest} class="{ses.reqtoken ? 'sc-dn' : ''}">Request recording&nbsp;<i style="font-size:1rem;" class="icon-request"></i></span>
            <a target="_blank" class="{ses.reqtoken ? '' : 'sc-dn'}" href="/request?r={ses.reqtoken}">This is the request URL</a>
            <div id="request-url-to-copy"  on:click={copyThisEl} style="font-size:1rem;margin-left:1rem;" class="sc-copy {ses.reqtoken ? '' : 'sc-dn'}">{server + "/request?r=" + ses.reqtoken}<i style="margin-left:1rem;" class="icon-docs"></i></div></td>
      </tr>
    </table>
    </div>
    
</div>
<!--
<h3>Where to store videos? On <b>{ses.store}</b></h3>
<a  on:click={() => setStorage('gdrive')} class="{ses.store=="gdrive" ? 'sc-dn' : ''}">Store on Google Drive (free) </a>
<a  on:click={() => setStorage('shrtct')} class="{ses.store=="shrtct" ? 'sc-dn' : ''}">Store on Shrtct </a>
-->

	{/if}

</div>
<div id="sc-theater" class="sc-dn theater">
  <div id="sc-theater-screen">
	<div id="sc-vidcontain" class="sc-dn">
    <!--<canvas style="display:none;" id="vidrec" class="dimensions videl"></canvas>-->
    <video class="sc-vidol {recordingScreen ? 'sc-dn' : ''}" muted id="sc-vidplayback"></video>
    {@html recordingScreen ? '<div>(SCREEN CAPTURED)</div>' : ''}
	<div id="sc-vidoverlay" class="sc-vidtxt">
        <div class="sc-video-seg-text">{curSeg ? curSeg.title :  ''}</div>
    </div>
	<div id="sc-theater-tools">
		<div class="sc-theater-screen-recorder-tools text-center" style="display: flex; flex-direction: column;">
      <div>
			  {segNow && segNow.indexOf("awaitingUpload")>-1 ? 'Uploading...' : ''}
      </div>
      {#if segNow && segNow.indexOf("awaitingUpload")==-1 }
        {#if curSeg && !segMx.isSet(curSeg, "recording")}
        <div on:click={()=>{if(!fallbackRecord) { segMx.set(curSeg,"recording") } else {doFallbackRecord(curSeg)}}} class="icon-btn">
          <i class="icon-record"></i>
          <br><span style="font-size:1rem;" id="record-text">{recResume ? 'CONTINUE' : 'RECORD'}</span>
        </div>
      {/if}
      {#if !segMx.isSet(curSeg,"paused")}
        <div  on:click={ ()=>{segMx.set(curSeg,"paused")}} class="icon-btn">
          <i class="icon-pause"></i>
          <br><span style="font-size:1rem;">{secondsRemaining > 0 ? secondsRemaining : 'PAUSE'}</span>
        </div>
      {/if}
      {#if recResume && segMx.isSet(curSeg,"paused")}
        <div on:click={ ()=>{segMx.set(curSeg,"stopRecording")}} class="icon-btn">
          <i class="icon-stop"></i>
          <br><span style="font-size:1rem;">STOP</span>
        </div>
      {/if}
      {#if segMx.isSet(curSeg,"paused")}
        <div on:click={ ()=>{if(!recResume || confirm("Are you sure?")) doCancel(curSeg)}} class="icon-btn">
          <i class="icon-cancel"></i>
          <br><span style="font-size:1rem;">CANCEL</span>
        </div>
      {/if}
      {#if !fallbackRecord && !recResume && segMx.isSet(curSeg,"paused")} 
        <div class="icon-btn" on:click={toggleScreenRecord}>
          <i class="{recordingScreen ? 'icon-toggle-on' : 'icon-toggle-off'}"></i>
          <br><span style="font-size:1rem;">&nbsp;SCREEN</span>
        </div>
      {/if}
    {/if}
    <input type="file" class="sc-dn" id="fallback-record" capture="user" accept="video/*">
		</div>
	  </div>
	</div>
  </div>
</div>


<div id="sc-stripe" class="sc-dn theater">
  <div id="sc-theater-screen">
	<div id="sc-vidcontain">

    <a on:click={() => closeTheater('sc-stripe') } > Close</a>
    
    <div id="choose-the-plan">
      <form id="plan">
        <p><label><input bind:group={selectedPlan} type="radio" name="planid" value={"uJE5kI"}>Lite - Monthly $9 = $9/mo</label></p>
        <p><label><input bind:group={selectedPlan} type="radio" name="planid" checked value={"f219wF"}>Lite - Year $54 = $4/mo</label></p>
        <p><label><input bind:group={selectedPlan} type="radio" name="planid" value={"S0OBwb"}>Business - Monthly $90 = $90/mo</label></p>
        <p><label><input bind:group={selectedPlan} type="radio" name="planid" value={"cwCPWv"}>Business - Yearly $450 = $45/mo</label></p>
        <a on:click={ () => { goToPay(selectedPlan) } }>Select Plan</a>
      </form>
    </div>
    <div id="stripe-pay"></div>
    <div id="card-errors"></div>
    <div id="card-element"> </div>

    <form id="payment-form">
      <div class="form-row">
        <label for="name">
          Name
        </label>
        <input id="name" name="name" placeholder="Jenny Rosen" required>
      </div>

      

      <button>Submit Payment</button>

      <!-- Used to display form errors. -->
        <div id="error-message" role="alert"></div>
    </form>

	  </div>
	</div>
</div>



<div id="sc-auth" class="sc-dn theater">
  <div id="sc-theater-screen">
	<div id="sc-vidcontain">

    <a on:click={() => {closeTheater('sc-auth'); sesMx.set(ses,["inactive","gDriveDenied"]) } } > Close</a>
    
    <p style="max-width:350px;">First, we need to sign you in to your Google account. 
    Your videos can be stored in your own Google Drive account for free. Ready?</p>
    <p><a class="sc-btn sc-btn-primary" on:click={ () => { 
            signInCb = () => { sesMx.set(ses, "ready"); if(thenDo) { thenDo(); thenDo=false; } }
      sesMx.set(ses, "signIn");
    }
    }>Allow Google Drive</a></p>

	  </div>
	</div>
</div>


<style>
.textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
}
.sc-copy:active {
  color:yellowgreen;
}
.sc-copy:active::before {
  transition: transform 2s;
  content: ' Copied! ';
  display: inline;
}
.icon-btn {
  padding: 0em;
  margin-bottom:1em;
  font-size: xx-large;
}
table {
      overflow-wrap: anywhere;
}

.sc-editable {
  display:block;
  padding: .33em;
  border:solid 2px;
  cursor: text;
  border-radius:5px;
  border:none;
  background-color: rgba(255,255,255,0.2);
  font-weight:100;
  font-family: sans-serif;
  font-size:300%;
  text-shadow: 0 0 black;
  color:rgba(255,255,255,0.95);
  
 }
  .sc-dn {
    display: none;
  }
  
.sc-session {
  cursor: pointer;
}
  .theater {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }
    #sc-vidplayback {
        max-width: 100%;

        /* Setting width & height to auto prevents the browser from stretching or squishing the video */
        width: auto;
        height: auto;
	}
	
	.sc-dimensions {
        width: 1280px;
        height: 720px;

    }

    #sc-vidcontain {
        position: relative;
		margin: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
    }

    #sc-vidcontain {
      width:100%;
      text-align: center;
    }

    .sc-vidtxt {
        position: absolute;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, .6);
        width: 100%;
        margin-bottom: 1.66em;
        text-align: center;
	}
	#sc-theater-screen {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(0,0,0,.9);
		width:100%; 
    height: 100%;
    z-index: 2147483647;

	}
	#sc-theater-tools {
		position: absolute;
        top: 0; left: 0; bottom: 0; right: 0;
		margin:auto;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
    text-align: center;
    align-self: flex-end;

		
	}
	.sc-theater-screen-recorder-tools {
		    background-color: rgba(0, 0, 0, .33);
		backdrop-filter: blur(15px);
		/* box-shadow: 0 0 5px 10px rgba(22,25,9,.6); */
		/* border-radius: 100px / 50px; */
    padding: 1em;
    margin-left: auto;
        margin-bottom: auto;
	}
	.sc-session-title-input {
		width: 100%;
		padding:.33em;
		margin-top:2em;
		margin-bottom:2em;
	}
  .sc-video-preview {
    max-width: 100%;
    /*transform: rotateY(180deg);*/
    max-height: 66vh;
    display: flex;
    margin-left:auto;
    margin-right: auto;
  }
  .sc-seg-title {
    cursor: pointer;
  }

  .sc-seg-wrapper-active {
  }
 
  .sc-title-combo {
    display:flex;
    white-space: nowrap;
    font-size: x-large;
    width:100%;
align-content: center;
  }
  .sc-title-combo-2 {
    width:100%;
  }

    .sc-title-combo-1 {
    align-self: center;
  }

</style>